import { createMuiTheme } from '@material-ui/core/styles';

const colorDarkGrey = '#1D1E22';
const colorLightGrey = '#D4D4DC';
const colorWhite = '#fff'
const colorYellow = '#FEDA6A';
const colorBlue = '#2B7BA6';

const theme = createMuiTheme({
	palette: {
		primary: {
			//main: colorDarkGrey,
			//dark: colorDarkGrey,
			//light: colorLightGrey
			main: colorBlue,
			contrastText: colorWhite
		},
		secondary: {
			main: colorYellow,
			contrastText: colorDarkGrey
			//contrastText: accentColorYellow
		},
		text: {
			primary: colorDarkGrey,
			secondary: colorLightGrey
		}
	},
	typography: {
		fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
		fontSize: 14,
		fontWeightLight: 100,
		fontWeightRegular: 300,
		fontWeightMedium: 400,
		h1: {
			fontSize: '2.125rem',
			fontWeight: 300,
			lineHeight: 1.235,
			letterSPacing: '0.00735em'
		},
		h2: {
			fontSize: '1.25rem',
			fontWeight: 400,
			lineHeight: 1.6,
			letterSPacing: '0.0075em'
		}
	},
	overrides: {
		MuiMenuItem: {
			// "&$selected": {
			// 	backgroundColor: colorLightGrey
			// }
			// selected: {
			// 	backgroundColor: colorLightGrey
			// }
		},
	}
});

export default theme;