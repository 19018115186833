import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'mobx-react';
import React from 'react';
import Main from './scenes/Main';
import RootStore from './stores/RootStore';
import theme from './Theme';
import LandingPage from './scenes/LandingPage';

const rootStore = new RootStore();

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Provider rootStore={rootStore}>
				<LandingPage />
				{/* <Main /> */}
			</Provider>
		</ThemeProvider>
	);
}

export default App;
