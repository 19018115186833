import React, { lazy, Suspense } from 'react';
import Section from '../components/Section';
import backgroundPhoto from '../assets/background.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link/Link';
import Box from '@material-ui/core/Box/Box';
import logo from '../assets/logo-light-big.webp';
import logoFallback from '../assets/logo-light-big.png';
import useCountdown from '../hooks/useCountdown';

const styles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	photoBackground: {
		backgroundImage: `url(${backgroundPhoto})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		height:'100vh',
		width:'100vw',
		position: 'absolute'
	},
	photoOverlay: {
		backgroundColor: 'rgba(0,0,0,.2)',
		height:'100%'
	},
	photoLink: {
		padding: theme.spacing(1),
		color: '#777',
		fontSize: '.6rem',
		position: 'absolute',
		bottom: 0,
		right: 0
	},
	content: {
		position: 'relative'
	},
	logo: {
		height:'25vh'
	}, 
	link: {		
		color: '#fff'
	}
}));


export default () => {
	const classes = styles();

	const countdown = useCountdown(5000, 1000, () => {
		window.location.href = "https://sanderdriesen.nl";
	});

	return (
		<>
			<Box display="flex" className={classes.root} alignItems="center" justifyContent="center">
				<div className={classes.photoBackground}>
					<div className={classes.photoOverlay} />
				</div>
				<Box className={classes.content} display="flex" flexDirection="column" alignItems="center">
					<Box>
						<picture>
							<source srcSet={logo} type="image/webp" />
							<img src={logoFallback} alt="Sander Driesen" className={classes.logo} />
						</picture>
					</Box>
					<Box m={8}>
						<Link className={classes.link} href="https://sanderdriesen.nl">
							Redirect to sanderdriesen.nl in {countdown / 1000}
						</Link>
					</Box>
				</Box>
				<Link 
					className={classes.photoLink} 
					href="https://www.pexels.com/photo/architecture-blur-bridge-buildings-314380"
					target="_blank">
					Photo by Steven Arenas from Pexels
				</Link>
			</Box>
		</>);
}
