import React, { useState, useEffect } from 'react';
import { ModuleKind } from 'typescript';

const useCountdown = (totalMiliseconds: number, interval: number, callback = () => {}) => {
  const [countdown, setCountdown] = useState(totalMiliseconds);

  const countdownFinished = (intervalHandle:any) => {
    clearInterval(intervalHandle);
    callback();
  }

  useEffect(() => {
    const intervalHandle = setInterval(() => {
        console.log('### tick');
        setCountdown(Math.max(countdown - interval, 0));
        if (countdown <= 0) countdownFinished(intervalHandle);
    }, interval);
    
    return () => {
        clearInterval(intervalHandle);
    };
  });

  return countdown;
}

export default useCountdown;